
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import { ShowDialog, CodeList } from "@/helper";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Home",
  components: { Header, Tail },
  setup() {
    const bkcolor = ref("white");
    const router = useRouter();
    const data = reactive({
      first: "",
      last: "",
      email: "",
      message: "",
      country: "+1",
      phone: "",
      check: {
        first: {
          msg: "can not null",
          err: false,
        },
        last: {
          msg: "can not null",
          err: false,
        },
        email: {
          msg: "can not null",
          err: false,
        },
        phone: {
          msg: "can not null",
          err: false,
        },
      },
    });
    const winScroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > 80) {
        bkcolor.value = "white";
      } else {
        bkcolor.value = "white";
      }
    };
    onMounted(() => {
      window.addEventListener("scroll", winScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", winScroll);
    });
    const _load_script = function (url: string, callback: any) {
      let head: any = document.querySelector("head");
      let script: any = document.createElement("script");
      let r = false;
      script.type = "text/javascript";
      script.src = url;
      if (callback) {
        script.onload = script.onreadystatechange = function () {
          if (!r && (!this.readyState || this.readyState == "complete")) {
            r = true;
            callback();
          }
        };
      }
      head.appendChild(script);
    };
    const win: any = window;
    win._show_thank_you = () => {
      console.log("send ok+++++++");
    };
    win._show_error = (id: string, message: string) => {
      console.log("send failed+++++++");
      console.log(message);
    };
    _load_script(
      "//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js",
      function () {
        win._form_serialize = win.serialize;
        console.log(win._form_serialize);
      }
    );
    let loading = false;
    const resetdata = () => {
      data.first = "";
      data.last = "";
      data.email = "";
      data.country = "+1";
      data.phone = "";
      data.message = "";
    };
    const handsubmit = () => {
      if (loading) return;
      loading = true;
      // check data
      if (data.first == "") {
        data.check.first.err = true;
        loading = false;
        return;
      } else {
        data.check.first.err = false;
      }
      if (data.last == "") {
        data.check.last.err = true;
        loading = false;
        return;
      } else {
        data.check.last.err = false;
      }
      if (data.email == "") {
        data.check.email.err = true;
        data.check.email.msg = "can not null";
        loading = false;
        return;
      } else {
        // check fmt
        const reg = new RegExp("^(\\w+\\.*\\w+)*@(\\w+\\.*\\w+)*$");
        if (reg.test(data.email) == false) {
          data.check.email.msg = "E-mail address is invalid";
          loading = false;
          return;
        }
        data.check.email.err = false;
      }
      const form_name = "_form_16_";
      var serialized = win
        ._form_serialize(document.getElementById(form_name))
        .replace(/%0A/g, "\\n");
      console.log(`json:${serialized}`);
      _load_script(
        "https://apifiny.activehosted.com/proc.php?" + serialized + "&jsonp=true",
        () => {
          //reset date
          console.log("handsubmit..... done....");
          resetdata();
          ShowDialog("Thanks for signing up!");
          loading = false;
        }
      );
    };
    const phonenum = computed(() => {
      return `${data.country}${data.phone}`;
    });
    const handfirst = (ismail: boolean) => {
      if (data.first) {
        data.check.first.err = false;
      }
      if (data.last) {
        data.check.last.err = false;
      }
      const reg = new RegExp("^(\\w+\\.*\\w+)*@(\\w+\\.*\\w+)*$");
      if (reg.test(data.email)) {
        data.check.email.err = false;
      } else if (ismail) {
        data.check.email.err = true;
        data.check.email.msg = "E-mail address is invalid";
      }
    };
    const gotolink = (url = "/contact", outsite = false) => {
      if (outsite) {
        window.location.href = url;
        return;
      }
      router.push(url);
    };
    const PhList = computed(() => {
      const res: Array<any> = [];
      Object.keys(CodeList).forEach((key) => {
        (CodeList as any)[key].map((it: any) => {
          res.push({
            title: it.english_name,
            key: it.phone_code,
          });
        });
      });
      return res;
    });
    const handcmd = (nn: string) => {
      data.country = `+${nn}`;
    };
    return { PhList, handcmd, gotolink, handfirst, phonenum, data, bkcolor, handsubmit };
  },
});
